/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useQueryParam, StringParam } from 'use-query-params';

import { Demos } from '@powdr/components';
import { Season, QueryParamNames } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeasonChange, useWindowResize } from '@powdr/hooks';
import { useStaticData } from '@powdr/web/src/hooks';
import GlobalStyle from '@powdr/web/src/utils/global-styles';

const DemoPageWrapper = styled.div``;

function DemoTemplate({ pageContext }) {
  const resize = useWindowResize();
  const [seasonParam] = useQueryParam(QueryParamNames.SEASON, StringParam);
  const { defaultSeason } = useStaticData();
  const {
    defaultTheme,
    setIsMobile,
    uiSeason,
    handleTheme,
  } = useContext(AppContext);
  const { handleSeasonChange } = useSeasonChange();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // Skip season logic if site doesn't use seasonality
    if (!defaultSeason) return undefined;
    if (!uiSeason || (defaultSeason === Season.SUMMER && !seasonParam && pathname === '/')) {
      // homepage with default summer override default
      handleSeasonChange(seasonParam === Season.SUMMER ? Season.SUMMER : defaultSeason);
    } else if (pathname !== '/') {
      // non homepage use param only
      handleTheme(
        (seasonParam === Season.SUMMER
          ? Season.SUMMER
          : Season.WINTER),
      );
    } else {
      // handle when theme doesn't get set
      handleTheme(
        defaultSeason,
      );
    }
    return undefined;
    // Don't add dependencies, we only want this to fire on first homepage load
  }, []);

  useEffect(() => {
    setIsMobile(!!(resize.windowWidth < 960));
  }, [resize.windowWidth, setIsMobile]);

  return (
    <DemoPageWrapper>
      <GlobalStyle theme={defaultTheme} />
      <Demos data={pageContext} />
    </DemoPageWrapper>
  );
}

export default DemoTemplate;

DemoTemplate.propTypes = {
  pageContext: PropTypes.instanceOf(Object).isRequired,
};

DemoTemplate.defaultProps = {

};
